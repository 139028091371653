import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext/UserContext';
const removeTralingZero = (num) => {

  return num.toString().replace(/(\.0+)$/,'');
 

}
const WithdrawalHistory = () => {
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const {userData} = useContext(UserContext);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_BASE_URL}/withdrawal-requests`, { withCredentials: true })
      .then(response => {
        setWithdrawalRequests(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        setError('Failed to load withdrawal requests.');
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="loader__container">
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
</div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>


<div className=" d-flex flex-column justify-content-center align-items-center ">
<p className='h4 font-bold text-white'> Total Withdrawal</p>
                    <p className='h1blnc font-bold text-white' ><span className='text-success font-bold me-1'>USD</span>{removeTralingZero(userData.total_withdrawal)}</p>
                </div>    
    {/* Header Row */}
    <div className="flex justify-between items-center p-3 bg-gray-800 bg-opacity-50 rounded-lg mt-6 text-white">
      <p className="font-semibold">Bank</p>
      <p className="font-semibold">Date</p>
      <p className="font-semibold">Amount</p>
      <p className="font-semibold">Status</p>
    </div>

    {/* Withdrawal History List */}
    <div className="withdraw-history mt-4">
      <div className="new-container">
        <div className="new-content">

          {/* Withdrawal Request Items */}
          <div className="new-list">
            {withdrawalRequests.map((request, index) => {
              const date = new Date(request.date);
              const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
              const formattedDate = date.toLocaleDateString('en-US', options);

              return (
                <div key={`${request.id}-${index}`} className="p-4  rounded-lg shadow-md mt-4">
                  <div className="flex justify-between items-center text-white">
                    <div className="text-sm">{request.bank_name}</div>
                    <div className="text-sm">{formattedDate}</div>
                    <div className="text-sm">${removeTralingZero(request.amount)}</div>
                    <div className={`text-sm p-1 rounded ${request.approved === 'approved' ? 'bg-green-500'  : request.approved === 'rejected' ? 'bg-red-500' : 'bg-yellow-400 text-black'}`}>
                      {request.approved === 'approved' ? 'Approved': request.approved === 'rejected' ? 'Rejected' : 'Pending'}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

        </div>
      </div>
    </div>

    </>
  );
};

export default WithdrawalHistory;
