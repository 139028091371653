import  { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../SideBarSection/Sidebar';
import '../Widgets/widgets.css';
import ApprovedUsersSpec from '../Users/ApprovedUsersSpec';
const Widgets = () => {
  const [dashboardData, setDashboardData] = useState({
    approvedUsersCount: 0,
    approvedUsersCountToday: 0,
    totalWithdrawal: 0,
    totalAmountToday: 0,
    unapprovedUnpaidUsersCount: 0,
    totalAmountTodayWithdrawal: 0,
    totalReceived: 0,
    totalReceivedToday: 0,
    backend_wallet: 0,
    users_balance: 0,
    users_bonus: 0,
    todayIncome: 0,
    totalIncome: 0,
    totalSalary: 0,
    will_give: 0,
    

  });

  const [loading, setLoading] = useState(true);
  function formatValue(value, returnAsString = true) {
    // Ensure the input is a valid number
    const numericValue = parseFloat(value);
  
    if (isNaN(numericValue)) {
      return returnAsString ? "0" : 0; // Return default value if not a valid number
    }
  
    // Fix to 2 decimal places and remove trailing zeros
    const fixedValue = parseFloat(numericValue.toFixed(2));
  
    // Return as string or number based on the parameter
    return returnAsString ? fixedValue.toString() : fixedValue;
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/dashboard-data`);
        setDashboardData(response.data.dashboardData);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);




  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        {loading ? (
null       ) : (
          <>
            <div >
            </div>
            <div className="task-container">

            <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Today Approved</span>
                </div>
                <p className='data'>{dashboardData.approvedUsersCountToday}</p>
              </div>
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Today Received</span>
                </div>
                <p className='data'>{dashboardData.totalReceivedToday}</p>
              </div>
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Today Withdrawal</span>
                </div>
                <p className='data'>{dashboardData.totalAmountTodayWithdrawal || 0}</p>
              </div>


              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Today Income</span>
                </div>
                <p className='data'>{dashboardData.todayIncome} </p>
              </div>

          

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Approved Users</span>
                </div>
                <p className='data'>{dashboardData.approvedUsersCount}</p>
              </div>
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Total Recived</span>
                </div>
                <p className='data'>{dashboardData.totalReceived}</p>
              </div>
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Total Incom</span>
                </div>
                <p className='data'>{dashboardData.totalIncome}</p>
              </div>
             

         

          

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Total Withdrawal</span>
                </div>
                <p className='data'>{Number(dashboardData.totalWithdrawal).toFixed(1)}</p>
              </div>

           

            
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Users Backend</span>
                </div>
                <p className='data'>{formatValue(dashboardData.backend_wallet)}</p>
              </div>
             
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Users Balance</span>
                </div>
                <p className='data'>{formatValue(dashboardData.users_balance)}</p>
              </div>     
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Users Bonus</span>
                </div>
                <p className='data'>{formatValue(dashboardData.users_bonus)}</p>
              </div>     
              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Will Pay</span>
                </div>
                <p className='data'>{dashboardData.will_give.toFixed(1)} </p>
              </div> 
{/* /* hisab brabar krny k liy 600.2 kia ha */}

              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Diffrence</span>
                </div>
                <p className="data">
                {formatValue(dashboardData.will_give - dashboardData.users_balance-dashboardData.backend_wallet+Number(dashboardData.today_wallet)    + Number(dashboardData.incrementAmount) )}
</p>
{Number(dashboardData.incrementAmount) }

              </div> 



              <div className="task" draggable="true">
                <div className="tags">
                  <span className="tag">Pending Users</span>
                </div>
                <p className='data'>{dashboardData.unapprovedUnpaidUsersCount}</p>
              </div>      
              </div>
<ApprovedUsersSpec/>
          </>
        )}
      </div>
    </div>
  );
};

export default Widgets;