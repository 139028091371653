import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import './DailyBonus.css';

const WeakBonus = () => {
  const { Userid, week_team, } = useContext(UserContext);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [clickedButtons, setClickedButtons] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(null); // New state for tracking loading button
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    const fetchClickedButtons = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/fetchClickedButtonsweek`, {
          withCredentials: true,
        });
        if (response.data.status === 'success') {
          setClickedButtons(response.data.clickedButtons);
        } else {
          setError('Failed to fetch clicked buttons.');
        }
      } catch (error) {
        setError('Failed to fetch clicked buttons.');
      } finally {
        setLoading(false);
      }
    };

    if (Userid) {
      fetchClickedButtons();
    } else {
      setLoading(false);
    }
  }, [Userid]);

  const handleButtonClick = async (buttonId) => {
    setLoadingButton(buttonId); // Set the loading state for the button
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/weeklytrackButton`, {
        userId: Userid,
        buttonId,
      });

      if (response.data.status === 'error' && response.data.message.includes('level 2 or above')) {
        setError(response.data.message);
        setShowErrorPopup(true);
      } else {
        setClickedButtons((prevClickedButtons) => ({
          ...prevClickedButtons,
          [buttonId]: true,
        }));
        setSuccessMessage('Payout requested successfully!');
        setShowSuccessPopup(true);
      }
    } catch (error) {
      setError('You Can Withdraw Salary on Level 2 or Above');
      setShowErrorPopup(true);
    } finally {
      setLoadingButton(null); // Reset loading state
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-100">
      <h5 className='h1 text-white'>Weekly Bonus</h5>

      {showErrorPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3 className='text-danger text-center mb-3 h3'>Sorry</h3>
            <p className='mb-3'>{'You Can Withdraw Salary on Level 2 or Above'}</p>
            <button className="popup-close-btn w-100" onClick={() => setShowErrorPopup(false)}>Close</button>
          </div>
        </div>
      )}

      {showSuccessPopup && (
        <div className="popup-overlay">
          <div className="popup-content d-flex justify-content-center align-items-center flex-column text-center gap-3">
            <p>{successMessage}</p>
            <button className="popup-close-btn w-100" onClick={() => setShowSuccessPopup(false)}>Close</button>
          </div>
        </div>
      )}

<div className="bon">
<div className="border rounded p-3 mt-3 bg-white overflow-hidden opacity-75">
        <div className="w-100 d-flex justify-content-between">
          Add 25 new members & get <span style={{ color: '#68e365', marginLeft: '5px', fontWeight: 'bold' }}>10$</span>
        </div>
        <p className='text-sm mb-4'>Team <span className='text-success'>{week_team>25?25:week_team}</span>/25</p>

        <div className="levels-container">
          <div className="progress-bar" style={{ width: `${(week_team / 25) * 100}%` }}>
            <div className={`glow-tip ${week_team > 0 ? '' : 'inactive'}`}></div>
          </div>
        </div>

        <button
          className={`btn btn-primary rounded-5 text-white animate mt-4 ${clickedButtons[1] || week_team < 25 ? 'disabled' : ''}`}
          onClick={() => handleButtonClick(1)}
          disabled={clickedButtons[1] || loadingButton === 1}
        >
          {loadingButton === 1 ? 'Sending...' : clickedButtons[1] ? 'Payout Requested' : 'Send Pay Out Request'}
        </button>
      </div>

      <div className="border rounded p-3 mt-3 bg-white overflow-hidden opacity-75">
        <div className="w-100 d-flex justify-content-between">
          Add 50 new members & get <span style={{ color: '#68e365', marginLeft: '5px', fontWeight: 'bold' }}>25$</span>
        </div>
        <p className='text-sm mb-4'>Team <span className='text-success'>{week_team>25?week_team-25:0}</span>/50</p>

        <div className="levels-container">
          <div className="progress-bar" style={week_team > 25 ? { width: `${((week_team - 25) / 50) * 100}%` } : { width: '0%' } }>
            <div className={`glow-tip ${week_team > 25 ? '' : 'inactive'}`}></div>
          </div>
        </div>

        <button
          className={`btn btn-primary rounded-5 text-white animate mt-4 ${clickedButtons[2] || week_team < 70 ? 'disabled' : ''}`}
          onClick={() => handleButtonClick(2)}
          disabled={clickedButtons[2] || loadingButton === 2}
        >
          {loadingButton === 2 ? 'Sending...' : clickedButtons[2] ? 'Payout Requested' : 'Send Pay Out Request'}
        </button>
      </div>
</div>

    </div>
  );
};

export default WeakBonus;
