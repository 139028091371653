import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import axios from 'axios';
import Modal from 'react-modal';
import '../Users/users.css';
import { Button } from 'react-bootstrap'; // Import Bootstrap Button
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Modal.setAppElement('#root');
import dayjs from 'dayjs';

const ApprovedUsersSpec = () => {
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editingUser, setEditingUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
    const [totalPages, setTotalPages] = useState(0);
    const [originalData, setOriginalData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const token = localStorage.getItem('adminToken');
    const [incrementAmounts, setIncrementAmounts] = useState({});
    const [userBackend, setUserBackend] = useState({});

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/approved-users-spec`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.data.success) {
                    const receivedData = response.data.approvedUsers;
                    setOriginalData([...receivedData]);
                    setData([...receivedData]);
                    setTotalPages(Math.ceil(receivedData.length / itemsPerPage));
                    setDisplayedData(receivedData.slice(0, itemsPerPage));
                    setUserBackend(response.data.approvedUsers[0].backend_wallet);
                    
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the approved users!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);




    const handleEdit = (user) => {
        setEditingUser(user);
        setModalIsOpen(true);
    };

    const handleSave = () => {
        const normalizedDate = editingUser.last_week_team_update
            ? dayjs(editingUser.last_week_team_update).format('YYYY-MM-DD')
            : null;
    
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/updateUser`, {
            ...editingUser,
            last_week_team_update: normalizedDate,
        })
            .then(response => {
                setModalIsOpen(false);
                const updatedData = data.map(user =>
                    user.id === editingUser.id ? editingUser : user);
                setData(updatedData);
            })
            .catch(error => {
                console.error('There was an error updating the user!', error);
            });
    };
    

    const sortData = () => {
        const sortedData = [...data];
        if (sortConfig.key !== null) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    };

    useEffect(() => {
        const sortedData = sortData();
        setData(sortedData);
    }, [sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        const sortedData = sortData();
        setData(sortedData);
    };

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedData(data.slice(startIndex, endIndex));
    }, [currentPage, itemsPerPage, data]);

    const handleIncrementChange = (userId, value) => {
        setIncrementAmounts(prev => ({
            ...prev,
            [userId]: parseFloat(value || 0),
        }));
    };
    const incrementBackendWallet = (userId, incrementValue) => {
        if (!incrementValue) {
            toast.error("Please enter a valid increment value!");
            return;
        }
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/increment-backend-wallet`, { 
            userId, 
            incrementAmount: incrementValue 
        })
        .then(response => {
            if (response.data.success) {
                console.log(userBackend);

                toast.warn("Backend wallet incremented successfully!",userBackend);
                
          
            } else {
                toast.success(response.data.message || "Failed to increment backend wallet.");
                setIncrementAmounts('');
window.location.reload();                

            }
        })
        .catch(error => {
            console.error("There was an error incrementing the backend wallet!", error);
            toast.error("An error occurred while processing your request.");

        });
        
    };
 
    return (
        <div className="home">
            <div className="homeContainer">
            <ToastContainer position="top-right" autoClose={3000} />

                <div className="listContainer">
                   

                    {isLoading ? (
                       
                            <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          </div>
             


                    ) : (
                        <table className="table table-striped">
                            <thead>
                                <tr className="w-100">
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('id')}>ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('name')}>Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('email')}>Email {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>

                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('balance')}>Balance {sortConfig.key === 'balance' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('backend_wallet')}>Backend {sortConfig.key === 'backend_wallet' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('backend_wallet')}>+Backend </th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('backend_wallet')}>+Backend </th>

                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('total_withdrawal')}>Withdrawal {sortConfig.key === 'total_withdrawal' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('team')}>Team {sortConfig.key === 'team' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light" onClick={() => requestSort('password')}>Password {sortConfig.key === 'password' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
                                    <th className="table-header bg-primary text-light">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
    {displayedData.map(user => (
        <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.balance}</td>
            <td>{Number(user.backend_wallet).toFixed(2)}</td>
            <td>
    <input
        type="number"
        placeholder="Enter increment"
        value={incrementAmounts[user.id] || ''}
        onChange={(e) => handleIncrementChange(user.id, e.target.value)}
    />
</td>
<td className="action-buttons p-3">
    <Button
        variant="warning"
        onClick={() => incrementBackendWallet(user.id, incrementAmounts[user.id] || 0)}
        className="ms-2"
    >
        +Backend
    </Button>
</td>

            <td>{user.total_withdrawal}</td>
            <td>{user.team}</td>
            <td>{user.password}</td>
            <td className="action-buttons p-3">
                <Button variant="warning" onClick={() => handleEdit(user)} className="ms-2">
                    Edit
                </Button>
            </td>
        </tr>
    ))}
</tbody>

                        </table>
                    )}
                   
                    <Modal isOpen={modalIsOpen} className="modal-content bg-light   ">
                        <h2>Edit User</h2>
                        <form>
                           
                            <div className="mb-3">
                                <label>Name:</label>
                                <input type="text" value={editingUser.name} onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Email:</label>
                                <input type="email" value={editingUser.email} onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Password:</label>
                                <input type="text" value={editingUser.password} onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Balance:</label>
                                <input type="number" value={editingUser.balance} onChange={(e) => setEditingUser({ ...editingUser, balance: e.target.value })} className="form-control" />
                            </div>
                            
                          
                            <div className="mb-3">
                                <label>Week Team:</label>
                                <input type="number" value={editingUser.week_team} onChange={(e) => setEditingUser({ ...editingUser, week_team: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>TRX ID:</label>
                                <input type="text" value={editingUser.trx_id} onChange={(e) => setEditingUser({ ...editingUser, trx_id: e.target.value })} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label>Total Withdraw:</label>
                                <input type="number" value={editingUser.total_withdrawal} onChange={(e) => setEditingUser({ ...editingUser, total_withdrawal: e.target.value })} className="form-control" />
                            </div>
                            <Button type="button" onClick={handleSave} className="save-button" variant="primary">Save</Button>
                            <Button onClick={() => setModalIsOpen(false)} className="close-button" variant="secondary">Close</Button>
                        </form>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default ApprovedUsersSpec;