import React, { useEffect, useState } from 'react';
import Sidebar from "../SideBarSection/Sidebar";
import DataTable from 'react-data-table-component';
import axios from 'axios';

const PendingUsers = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

    // Fetch pending users
    const fetchPendingUsers = (term) => {
        setIsLoading(true);
        axios
            .get(`${import.meta.env.VITE_API_BASE_URL}/pending-users?searchTerm=${term}`)
            .then(response => {
                if (response.data.success) {
                    setData(response.data.pendingUsers);
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the pending users!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Debouncing logic
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTerm(searchTerm);
        }, 500); // Debounce delay in milliseconds

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedTerm) {
            fetchPendingUsers(debouncedTerm);
        } else {
            setData([]);
        }
    }, [debouncedTerm]);

    // Handle search input change
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle delete user
    const handleDelete = (user) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            axios
                .delete(`${import.meta.env.VITE_API_BASE_URL}/delete-user/${user.id}`)
                .then(response => {
                    if (response.data.success) {
                        const updatedData = data.filter(u => u.id !== user.id);
                        setData(updatedData);
                    } else {
                        alert('Error deleting user: ' + response.data.message);
                    }
                })
                .catch(error => {
                    alert('Error deleting user: ' + error);
                });
        }
    };

    // Delete 7 days old users
    const handleDelete7DaysOldUsers = () => {
        const confirmDelete = window.confirm("Are you sure you want to delete users who are 7 days old and have not been approved or paid?");
        if (confirmDelete) {
            axios
                .delete(`${import.meta.env.VITE_API_BASE_URL}/delete-7-days-old-users`)
                .then(response => {
                    if (response.data.success) {
                        alert('Users deleted successfully');
                        fetchPendingUsers(searchTerm);
                    } else {
                        alert('Error deleting users');
                    }
                })
                .catch(error => {
                    alert('Error deleting users: ' + error);
                });
        }
    };

    // Define table columns
    const columns = [
        { name: 'ID', selector: row => row.id, sortable: true, center: true },
        { name: 'Name', selector: row => row.name, sortable: true, center: true },
        { name: 'Email', selector: row => row.email, sortable: true, center: true },
        { name: 'Phone Number', selector: row => row.phoneNumber, sortable: true, center: true },
        { name: 'Address', selector: row => row.completeAddress, left: true },
        {
            name: 'Action',
            cell: row => (
                <button
                    className="text-white bg-red-500 hover:bg-red-600 px-4 py-1 rounded-md text-sm"
                    onClick={() => handleDelete(row)}
                >
                    Delete
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className="flex flex-col md:flex-row">
            <Sidebar />
            <div className="flex-1 p-6 bg-gray-100">
                <button
                    className="mb-4 px-6 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-lg text-sm"
                    onClick={handleDelete7DaysOldUsers}
                >
                    Delete 7 Days Record
                </button>
                <div className="bg-white shadow-md rounded-lg p-4">
                    <input
                        type="text"
                        className="block w-full mb-4 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                    {isLoading ? (
                        <div className="text-center py-4">Loading...</div>
                    ) : (
                        <DataTable
                            title="Total Pending Users"
                            columns={columns}
                            data={data}
                            pagination
                            highlightOnHover
                            className="table-auto w-full border-collapse border border-gray-300 text-sm"
                            customStyles={{
                                rows: {
                                    style: {
                                        minHeight: '72px',
                                    },
                                },
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PendingUsers;
