import { ArrowPathIcon, DocumentCurrencyDollarIcon, ShareIcon } from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext } from '../UserContext/UserContext';
import TopBar from '../TopBar';
const features = [
  {
    name: 'You Can Earn',
    description:
      'Earn daily rewards by completing simple daily tasks! Participate actively to unlock exciting bonuses and boost your earnings. Stay consistent, and watch your rewards grow as you accomplish more each day',
    icon: DocumentCurrencyDollarIcon,
  },
  {
    name: 'Why to Invite',description: 'Invite your friends and expand your earning potential! Get rewarded with bonuses for every successful referral and build a stronger community together.',icon: ShareIcon,
  },
  {name: 'Get Bonuses and Offers',description: 'Earn exclusive bonuses and special offers by completing tasks and staying engaged. Maximize your rewards and enjoy new opportunities every day!',icon: ArrowPathIcon,
  },

]

export default function TeamDetails() {
  const { userData } = useContext(UserContext);

  const [inviteLink, setInviteLink] = useState('');
  useEffect(() => {
    if (userData.id) {
      const link = `https://metroearn.com/signup?ref=${userData.id}`;
      setInviteLink(link);
    }
  }, [userData.id]);
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    toast.success('Link copied to clipboard!');
  };
  return (
    <>

<div className="m-3 min-h-screen relative">

<Toaster />

<div className="fixed inset-0 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900"></div>
<TopBar />

<div className="relative px-8 lg:px-8">
  <div className="mx-auto max-w-2xl lg:text-center mt-10">
    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Everything you need to get started
    </p>
    <button type="button" className="text-sm btn bg-indigo-500 hover:bg-indigo-600 text-white w-full rounded-4 ml-auto mr-2 mt-2" onClick={copyLinkToClipboard}>Copy Your Link</button>
  </div>

  <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
      {features.map((feature) => (
        <div key={feature.name} className="relative pl-16">
          <dt className="text-base font-semibold leading-7 text-gray-100">
            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
              <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
            </div>
            {feature.name}
          </dt>
          <dd className="mt-2 text-base leading-7 text-gray-300">{feature.description}</dd>
        </div>
      ))}
    </dl>
  </div>
</div>
</div>


    </>)
}
