import React, { useState } from 'react';

import DailyBonus from './DailyBonus';
import TopBar from '../TopBar';
import BonusCard from '../CardBonus';


const Bonus = () => {
  const [activeTab, setActiveTab] = useState('daily');
  
  return (
    <>
    
<div className="p-3  bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 ">      
      <TopBar/>

      <div className="relative z-10 flex justify-center mt-5">
        <div className="flex space-x-4">
        <button 
            className={`py-2 px-4 text-lg font-semibold rounded-lg transition-colors ${activeTab === 'level_up' ? 'bg-green-500 text-white' : 'bg-gray-700 text-gray-300 hover:bg-green-600'}`}
            // onClick={() => setActiveTab('level_up')}
          >
            Level Up Bonus 
          </button>
          <button 
            className={`py-2 px-4 text-lg font-semibold rounded-lg transition-colors ${activeTab === 'daily' ? 'bg-green-500 text-white' : 'bg-gray-700 text-gray-300 hover:bg-green-600'}`}
            onClick={() => setActiveTab('daily')}
          >
            Daily Bonus
          </button>
         
        </div>
      </div>

      <div className="relative z-10 mt-8">
      {activeTab === 'level_up' && <BonusCard />}

        {activeTab === 'daily' && <DailyBonus />}
      </div>
    </div>
    </>
  );
};

export default Bonus;
